<template>
    <div class="p-relative">
        <v-tabs
            v-model="searchType"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab>{{ $t("Поиск_по_ИИН") }}</v-tab>
            <v-tab>{{ $t("Поиск_по_ФИО") }}</v-tab>

        </v-tabs>
        <v-tabs-items
            v-model="searchType"
            class="tab-content-panel"
        >
            <v-tab-item>
                <!--Форма поиска по ИИН-->
                <v-form class="wrapperForm" ref="formIIN" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <v-row class="two-columns-field-in-one-box" no-gutters>                                    
                                        <v-col cols="12" sm="12" md="6">  
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("ИИН") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterIin.iin"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        @keypress="filterNumberField()"
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6"></v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="blue-grey"
                                        text
                                        depressed
                                        dense
                                        @click="clearFilterIin"
                                    >
                                        {{ $t("Сбросить") }}
                                    </v-btn>

                                    <v-btn
                                        color="cyan"
                                        text
                                        depressed
                                        dense
                                        @click="setFilter"
                                        :disabled="loading"
                                    >
                                        {{ $t("Поиск") }}
                                    </v-btn>

                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>

            <v-tab-item>
                <!--Форма поиска ФИО-->
                <v-form class="wrapperForm" ref="formFIO" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <v-card flat>
                                <v-card-text>
                                    <v-row class="two-columns-field-in-one-box" no-gutters>                                    
                                        <v-col cols="12" sm="12" md="6">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Фамилия") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterFio.surname"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Имя") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterFio.name"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Отчество") }}</label>
                                                </v-col>
                                                <v-col  cols="12" sm="12" md="8">
                                                    <v-text-field
                                                        v-model="filterFio.patronymic"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                    >
                                                    </v-text-field>  
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ $t("Дата_рождения") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <v-menu 
                                                        v-model="dateMenuVisibleDateBirthFrom"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="filterFio.birthDate | formattedDate"
                                                                @keydown="onBirthDateFromKeyDown"
                                                                @paste="onPasteBirthDateFrom"
                                                                :readonly="filterFio.birthDate != null"
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                :placeholder="$t('дд.мм.гггг')"
                                                                clearable
                                                                @click:clear="filterFio.birthDate = null"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <div class="d-flex">
                                                            <v-date-picker 
                                                                v-model="filterFio.birthDate"
                                                                @input="dateMenuVisibleDateBirthFrom = false"
                                                                color="teal"
                                                                :first-day-of-week="1"
                                                            >
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showMonthSelectorBirthFrom = true, showYearSelectorBirthFrom = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_месяц") }}
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showYearSelectorBirthFrom = true, showMonthSelectorBirthFrom = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_год") }}
                                                                </v-btn>
                                                            </v-date-picker>

                                                            <v-card
                                                                v-if="showYearSelectorBirthFrom"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showYearSelectorBirthFrom = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>    
                                                                        </v-col> 
                                                                        <v-col
                                                                            v-for="year in years"
                                                                            :key="year"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectYearBirthFrom(year)"
                                                                            >
                                                                                {{ year }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>

                                                            <v-card
                                                                v-else-if="showMonthSelectorBirthFrom"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showMonthSelectorBirthFrom = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col
                                                                            v-for="(month, index) in months"
                                                                            :key="index"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectMonthBirthFrom(index)"
                                                                            >
                                                                                {{ month }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </div>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6"></v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        color="blue-grey"
                                        text
                                        depressed
                                        dense
                                        @click="clearFilterFio"
                                    >
                                        {{ $t("Сбросить") }}
                                    </v-btn>

                                    <v-btn
                                        color="cyan"
                                        text
                                        depressed
                                        dense
                                        @click="setFilter"
                                        :disabled="loading"
                                    >
                                        {{ $t("Поиск") }}
                                    </v-btn>

                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item>

        </v-tabs-items>
            
        <v-data-table
            class="elevation-1 customization-list-table pagination-list-table"
            :headers="headers"
            :options.sync="options"
            :page.sync="options.page"
            :items="entities"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
            @page-count="paginationPageCount = $event"
            :loading="loading"
            @dblclick:row="(event, data) => onResponseItemClick(data.item.requestId, data.item.responseIndex)"
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>
            
            <template v-slot:[`item.birthDate`]="{ item }">
                {{ $moment(item.birthDate).format('DD.MM.yyyy') }}
            </template>

            <template v-slot:[`item.regionRu`]="{ item }">
                <div class="etw-text-container">
                    <div class="etw-text" v-tooltip.top-center="locale == 'ru' ? item.regionRu : item.regionKz">
                        {{ locale == 'ru' ? item.regionRu : item.regionKz }}
                    </div>
                </div>
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import i18n from '@/i18n';
import sys from '@/services/system';
import { mapActions} from 'vuex';

export default {
    name: "GbdflSearch",
    computed: {
        headers() {
            return [
                { text: i18n.t("ИИН"), value: 'hash', width: '110px', sortable: false },
                { text: i18n.t("Фамилия_Имя_Отчество"), value: 'fullName', width: '350px' },
                { text: i18n.t("Дата_рождения"), value: 'birthDate', width: '150px', sortable: false },
                { text: i18n.t("Территориальная_единица"), value: 'regionRu', sortable: false },
                { text: i18n.t("Адрес"), value: 'address' }
            ];
        },
        options: {
            get: function() {
                return this.$store.getters['saylau/lists/gbdflSearch/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/gbdflSearch/SET_TABLE_OPTION', newValue);
            }
        },
        searchType: {
            get: function() {
                return this.$store.getters['saylau/lists/gbdflSearch/getSearchType'];
            },
            set: function(newValue) {                
                this.$store.commit('saylau/lists/gbdflSearch/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/gbdflSearch/SET_SEARCH_TYPE', newValue);
            }
        },
        filterFio: {
            get: function() {
                return this.$store.getters['saylau/lists/gbdflSearch/getFilterFio'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/gbdflSearch/SET_FILTER_FIO', newValue);
            }
        },
        filterIin: {
            get: function() {
                return this.$store.getters['saylau/lists/gbdflSearch/getFilterIin'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/gbdflSearch/SET_FILTER_IIN', newValue);
            }
        },
        loading() {
            return this.$store.getters['saylau/lists/gbdflSearch/isLoading'];
        },
        entities() {
            return this.$store.getters['saylau/lists/gbdflSearch/getEntities'];
        },
        total() {
            return this.$store.getters['saylau/lists/gbdflSearch/getTotal'];
        },
        locale() {
            return this.$i18n.locale;
        },
    },
    data() {
        return {
            dateMenuVisibleDateBirthFrom: false,

            showYearSelectorBirthFrom: false,
            showMonthSelectorBirthFrom: false,
            years: Array.from({ length: 125 }, (_, i) => new Date().getFullYear() - i),
            months: [
                i18n.t('Январь'), i18n.t('Февраль'), i18n.t('Март'), i18n.t('Апрель'), i18n.t('Май'), i18n.t('Июнь'), 
                i18n.t('Июль'), i18n.t('Август'), i18n.t('Сентябрь'), i18n.t('Октябрь'), i18n.t('Ноябрь'), i18n.t('Декабрь')
            ],

            paginationPageCount: 0,
        }
    },
    methods: {
        ...mapActions('saylau/lists/gbdflSearch', ['addGbdflSearch']),
        onResponseItemClick(id, index) {
            //this.$router.push({ name: 'VoterSearch', params: { id, index: index.toString() } });
            this.$router.push({ name: 'Voter', params: { id, index: index.toString(), fromSearchGBDFl: true } });
        },
        clearFilterFio() {
            this.filterFio = {
                surname: "",
                name: "",
                patronymic: "",
                birthDate: null
            };
            this.$store.commit('saylau/lists/gbdflSearch/SET_TABLE_DATA', { items:[], total: 0 });
        },
        clearFilterIin() {
            this.filterIin = {
                iin: ""
            };
            this.$store.commit('saylau/lists/gbdflSearch/SET_TABLE_DATA', { items:[], total: 0 });
        },
        async setFilter() {
            this.$store.commit('saylau/lists/gbdflSearch/SET_TABLE_OPTION_PAGE', 1);
            await this.addGbdflSearch();
        },
        filterNumberField(evt){
            return sys.filterNumberField(evt, 12);
        },
        selectYearBirthFrom(year) {
            const date = new Date(this.filterFio.birthDate || new Date());
            date.setFullYear(year);
            this.filterFio.birthDate = date.toISOString().substring(0, 10);
            this.showYearSelectorBirthFrom = false;
        },
        selectMonthBirthFrom(monthIndex) {
            const date = new Date(this.filterFio.birthDate || new Date());
            date.setMonth(monthIndex);
            this.filterFio.birthDate = date.toISOString().substring(0, 10);
            this.showMonthSelectorBirthFrom = false;
        },
        onBirthDateFromKeyDown(e) {
            if (this.isCtrlPressed && e.keyCode == 86) {
                this.isCtrlPressed = false;
                return;
            }

            if (e.keyCode == 17) {
                this.isCtrlPressed = true;

                setTimeout(() => {
                    this.isCtrlPressed = false;
                }, 250);
            }

            if (e.keyCode == 8)
                return;

            let isNumber = /^\d+$/.test(e.key);

            if (isNumber && e.target.value.length < 10) {
                e.target.value += e.key;

                switch (e.target.value.length) {
                    case 2:
                    case 5:
                        e.target.value += '.';
                        break;

                    default:
                        break;
                }

                if (e.target.value.length == 10) {
                    let date = this.$moment(e.target.value, 'DD.MM.YYYY', true);

                    if (date.isValid())
                        this.filterFio.birthDate = date.format('YYYY-MM-DD');
                }
            }
            
            e.preventDefault();
        },
        onPasteBirthDateFrom(e) {
            e.stopPropagation();
            e.preventDefault();

            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('Text');

            let date = this.$moment(pastedData, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY', 'DD.MM.YY', 'D.MM.YY', 'D.M.YY'], true);

            if (date.isValid()) {
                this.filterFio.birthDate = date.format('YYYY-MM-DD');
                this.dateMenuVisibleDateBirthFrom = true;
                return;
            }
            else {
                this.$notify.warning(this.$t("Данные_в_буфере_невалидны_вставка_невозможна."));
            }
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    }
}
</script>